@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .#{$moduleNameSpace} {

  .carousel {
    display: none;
  }

  a.spa-btn--medium {
    line-height: 1.3;
    height: auto;
  }

  .headline{
    h2 {
      text-align: center;
      line-height: 16px;
      font-size: 20px;
      margin-top: 55px;
      margin-bottom: 15px;

      @media #{$medium-up}{
        line-height: 24px;
        font-size: 28px;
        margin-top: 75px;
        margin-bottom: 25px;
      }

      @media #{$large-up}{
        line-height: 30px;
        font-size: 34px;
        margin-top: 85px;
      }
    }
  }

  .slick-track {
    width: 75%;
    height: auto;
    margin: auto;
    margin-bottom: $spacing-20;

    @media #{$medium-up} {
      width: 75%;
    }
  }

  .image {
    padding: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


    @media #{$medium-up} {
      height: 285px;
      width: 50%;
    }

    @media #{$medium-only} {
      background-position: left 0;
    }

    @media #{$large-up} {
      height: 230px;
    }
  }

  .content-div {
    &.dark-blue {
      background-color: $color-brand-secondary;
    }
    &.light-blue {
      background-color: $color-royal-two;
    }
    &.red {
      background-color: $color-regal-two;
    }
  }

  .content-well {
    &.dark-blue {
      background-color: $color-brand-secondary;
    }
    &.light-blue {
      background-color: $color-royal-two;
    }
    &.red {
      background-color: $color-regal-two;
    }

    padding: $spacing-20 $spacing-10 $spacing-20 18px;
    height: auto;
    float: left;
    display: none;
    -webkit-animation: fadein 2s; 
       -moz-animation: fadein 2s; 
        -ms-animation: fadein 2s; 
         -o-animation: fadein 2s; 
            animation: fadein 2s;

    &.currentSelection {
      display: block;
      @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
      }

      &:focus {
        outline: dotted 1px $color-black;
      }
    }

    @media #{$medium-up}{
      padding: $spacing-20 $spacing-40 44px $spacing-40;
      height: 285px;
      width: 50%;
      position: absolute;
      left: 50%;
      top: 0;
    }

    @media #{$large-up}{
      padding: $spacing-20 $spacing-40 $spacing-48 $spacing-40;
      height: 230px;
    }

    h3 {
      font-size: 28px;
      color: $white;
      margin-bottom: $spacing-10;

      @media #{$medium-up}{
        margin-bottom: 15px;
        line-height: 1.6;
      }

      @media #{$large-up}{
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
  }

  ul.carousel-nav {
    list-style: none;
    height: 35px;
    overflow-y: hidden;
    margin: $spacing-10 auto 0 auto;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 5px;
    }
    a {
      margin: 0;
      background-color: $color-gray-s20;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: inline-block;
      cursor: pointer;
      &:focus {
        outline: -webkit-focus-ring-color auto 5px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 5px;
      }
    }

      .spa-slider-circle-btn:hover {
        background: #A29282;
      }
      .slick-active .spa-slider-circle-btn,
      .slick-active .spa-slider-circle-btn:hover,
      .slick-active .spa-slider-circle-btn:focus {
        background: #0052C2;
      }

  }
  .circle-container {
    position: relative;
  }

  button.circle {
    background-color: $color-gray-s20;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    padding: 0;

    &:hover {
      background: #A29282;
    }

    &.currentSelection {
      background-color: $color-royal-one;
    }

  }

	.single-image {
		background-image: url({IMAGE||assets/images/site/online-banking/slideshow-images/u458.png});

		@media #{$medium-up} {
			background-image: url({IMAGE||assets/images/site/online-banking/slideshow-images/u458_b.png});
		}
	}
}